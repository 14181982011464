function AutoReplyMessage({
  lang,
  info,
  setInfo,
  status,
  errorAutoReplyMessageBusinessHours,
  setErrorAutoReplyMessageBusinessHours,
  setErrorAutoReplyMessage,
  errorAutoReplyMessage,
}) {
  const [firstReply, setFirstReply] = React.useState(false);
  const [autoReply, setAutoReply] = React.useState(false);
  const [autoReplyMessage, setAutoReplyMessage] = React.useState("");

  React.useEffect(() => {
    setFirstReply(
      status === "business_hours"
        ? info.settingInfo.fisrt_reply_message.is_on
        : info.settingInfo.awayBusinessMessage.fisrt_reply_message.is_on
    );
    setAutoReply(
      status === "business_hours"
        ? info.settingInfo.auto_reply_message.is_on
        : info.settingInfo.awayBusinessMessage.auto_reply_message.is_on
    );
    setAutoReplyMessage(
      status === "business_hours"
        ? info.settingInfo.auto_reply_message.text
        : info.settingInfo.awayBusinessMessage.auto_reply_message.text
    );
  }, [info]);

  const updateInfoData = (value, tag) => {
    const newInfo = _.cloneDeep(info);

    if (tag === "firstReply") {
      if (status === "business_hours") {
        newInfo.settingInfo.fisrt_reply_message.is_on = value;
      } else
        newInfo.settingInfo.awayBusinessMessage.fisrt_reply_message.is_on =
          value;
    } else if (tag === "autoReply") {
      setAutoReply(value);
      if (status === "business_hours")
        newInfo.settingInfo.auto_reply_message.is_on = value;
      else
        newInfo.settingInfo.awayBusinessMessage.auto_reply_message.is_on =
          value;
    } else if (tag === "autoReplyMessageText") {
      setAutoReplyMessage(value);
      if (status === "business_hours")
        newInfo.settingInfo.auto_reply_message.text = value;
      else
        newInfo.settingInfo.awayBusinessMessage.auto_reply_message.text = value;
    }

    setInfo(newInfo);
  };

  return (
    <div>
      <div
        class="body -scrollable sp_margin"
        ng-form
        name="settingForm"
        ng-hide="isLoading"
      >
        <div class="sp-section">
          <div class="sp-row">
            <div class="sp-col">
              <span class="sp-label" translate="__setting_first_reply_message">
                {lang.__setting_first_reply_message}
              </span>
            </div>
            <Toggle defaultStatus={firstReply}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => updateInfoData(!firstReply, "firstReply")}
                />
                <div class="toggle-button cursor-pointer">
                  <span></span>
                </div>
              </label>
            </Toggle>
          </div>
          <div class="sp-row -form-item">
            <div class="sp-text-help">
              {lang.__setting_first_reply_message_description}
            </div>
          </div>
        </div>
        <div class="sp-section">
          <div class="sp-row">
            <div class="sp-col">
              <p class="sp-label">{lang.__setting_auto_reply_message}</p>
            </div>
            <Toggle defaultStatus={autoReply}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => updateInfoData(!autoReply, "autoReply")}
                />
                <div class="toggle-button cursor-pointer">
                  <span></span>
                </div>
              </label>
            </Toggle>
          </div>
          <div class="sp-row -form-item">
            <div
              class="sp-text-help"
              translate="__setting_auto_reply_message_description"
            >
              {lang.__setting_auto_reply_message_description}
            </div>
          </div>
          {autoReply ? (
            <>
              <input
                type="text"
                class="sp-input"
                style={{
                  border:
                    (errorAutoReplyMessage && status === "business_hours") ||
                    (errorAutoReplyMessageBusinessHours &&
                      status === "outside_business_hours")
                      ? "1px solid red"
                      : "",
                  marginBottom: "5px",
                }}
                name="autoReplyMessageText"
                value={autoReplyMessage}
                onChange={(e) => {
                  updateInfoData(e.target.value, "autoReplyMessageText");
                  if (
                    errorAutoReplyMessage ||
                    errorAutoReplyMessageBusinessHours
                  ) {
                    if (status === "business_hours") {
                      setErrorAutoReplyMessage(false);
                    } else {
                      setErrorAutoReplyMessageBusinessHours(false);
                    }
                  }
                }}
                required
              />
              {(errorAutoReplyMessage && status === "business_hours") ||
              (errorAutoReplyMessageBusinessHours &&
                status === "outside_business_hours") ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {lang.__please_fill_out}
                  {lang.__setting_auto_reply_message}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
